@import 'vars'

.content
  font-size: 1.8rem
  line-height: 1.33
  max-width: 100vw
  h2
    margin-bottom: 3rem
    font-size: 2.2rem
    line-height: 3rem
  h3
    font-size: 2.8rem
    line-height: 3.8rem
    margin: 2rem 0
    @media #{$mq-lg}
      font-size: 3.2rem
      line-height: 3.8rem

// Blockquotes

.content-blockquote
  background-color: $color-contrast-a
  color: $color-mute-700
  font-size: 2.4rem
  font-weight: 300
  line-height: 1.33
  margin: 2.8rem -3rem 2rem -3rem
  padding: 3.6rem 4.4rem 0 4.5rem
  position: relative
  quotes: "“" "”" "‘" "’"
  p
    display: inline-block
    padding-bottom: 2.6rem
  @media #{$mq-lg}
    float: left
    font-size: 3.0rem
    line-height: 1.47
    margin: 2.8rem 5rem 2.8rem -50%
    padding-left: 30%
    width: 100%
  &:before
    content: open-quote
    font-size: 2.8rem
    font-weight: bold
    left: 2rem
    line-height: -0.25rem
    position: absolute
    top: 1rem
    @media #{$mq-lg}
      top: 1rem
      font-size: 4.5rem
      left: 26%
  &:after
    position: absolute
    bottom: 2rem
    right: 2rem
    content: close-quote
    font-size: 2.8rem
    font-weight: bold
    line-height: 0
    position: absolute
    @media #{$mq-lg}
      font-size: 4.5rem

// Content Title

.content__title
  font-size: 5.2rem
  line-height: 5.8rem
  padding: 3rem 0 1.5rem 0
  width: calc(100% - 10rem) // Minus ~ social width
  @media #{$mq_xs}
    padding: 2rem 0 0.5rem 0
    width: 100%
    font-size: 3.2rem
    line-height: 3.8rem

.content__title-sm
  font-size: 3.2rem
  line-height: 1.13
  margin: 3rem 0 1.5rem 0
  width: calc(100% - 10rem) // Minus ~ social width
  @media #{$mq_xs}
    margin: 2rem 0 0.5rem 0
    width: 100%

// Promotional Flags

.content__flag
  color: $color-error
  margin: 1rem 0

// Content Header

.content__header-video
  position: relative
  z-index: 3
  background: $color-contrast-b

.content__header-video-wrap
  max-width: 117rem
  margin: 0 auto

// Share

.content__share
  position: absolute
  top: 1rem
  right: 1rem

.content__share--interior
  top: 4.5rem

// Category

.content__category
  font-size: 1.6rem
  line-height: 2rem
  font-weight: bold
  color: $color-mute-350
  text-transform: uppercase
  margin-bottom: 3rem
  a
    color: $color-mute-350
  @media #{$mq_xs}
    margin-bottom: 2rem

// Byline

.content__bylineta
  color: $color-mute-700
  font-size: 1.6rem
  line-height: 2rem
  margin-bottom: 3rem

.content__byline-date
  color: $color-mute-350
  margin-bottom: 2rem

// Body

.content__body
  position: relative
  max-width: 77rem
  margin: 0 auto
  font-size: 1.6rem
  line-height: 2rem
  word-break: break-word

.content__body-main
  line-height: 2rem
  a
    position: relative
    color: $color-contrast-b
    font-weight: bold
    text-decoration: none
    border-bottom: 4px solid $color-primary
    line-height: .9
    display: inline-block

.content__body-main--larger
  .ctd-body
    p
      font-size: 1.8rem
      line-height: 1.33
      color: $color-mute-700

.content__spacer
  height: 3rem

// Disclaimer

.content__disclaimer
  margin: 3rem 0
  font-size: 1.4rem
  font-style: italic
  @media #{$mq_xs}
    margin: 2rem 0

// Related

.content__related
  position: relative
  background: $color-primary
  padding: 6rem 10rem 3rem 10rem
  h3
    margin: 0
    font-size: 3.2rem
    color: $color-contrast-b
  ul
    margin: 0
    padding: 0
    list-style: none
    li
      display: flex
      margin: 2rem 0
      padding: 2rem 0
      width: 100%
      border-bottom: 1px solid $color-contrast-b
      &:last-child
        border-bottom: none
      a
        color: $color-contrast-b
        display: inline-block
        padding: 0 0 0 1rem
  @media #{$mq_xs}
    padding: 2rem
    h3
      font-size: 2.8rem

.content__related-heart
  position: absolute
  bottom: 2rem
  right: 2rem

// Tags

.content__tags
  display: flex
  justify-content: flex-start
  align-items: center
  border-top: 1px solid $color-mute-500
  padding: 2rem 0
  h3
    margin-right: 1rem
    font-size: 1.6rem
    font-weight: 400
    color: $color-mute-375
    white-space: nowrap
  ul
    margin: 0
    padding: 0
    list-style: none
    li
      float: left
      padding: 1rem
      margin: 0.5rem 1rem 0.5rem 0
      border-radius: 4px
      background: $color-contrast-a
      transition: background $hoverTime
      cursor: pointer
      a
        color: $color-contrast-b
        display: block
      &:hover
        background: $color-primary

// Embedded Player

.content__embedded-player
  z-index: 1
  position: relative
  text-align: center
  h2
    margin: 2rem 0
  h4
    margin: 3rem 0
    font-size: 1.6rem

.content__embedded-player-player
  box-shadow: 0px 0px 10px 0px rgba($color-contrast-b, .5)

.content__btn-related
  color: $color-mute-300
  font-weight: bold
