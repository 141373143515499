@import 'vars'

.page-error
  width: 100%
  text-align: center
  font-size: 3rem
  display: flex
  justify-content: center
  align-items: center
  position: relative
  h2
    color: $color-primary
    font-size: 1.8rem
  h1
    font-size: 4rem

.page-error-content
  background: $color-mute-700
  color: $color-contrast-a
  height: 100vh
  width: 100%
  display: flex
  justify-content: center
  align-items: center
  padding: 4rem

.page-error-content__body
  font-size: 1.6rem