@import 'vars'

.category-grid
  position: relative
  display: flex
  margin: 2rem 0 0 0

.category
  display: flex
  justify-content: flex-start
  align-items: center
  flex-grow: 2
  font-size: 1.6rem
  line-height: 2rem
  font-weight: bold
  color: $color-mute-350
  text-transform: uppercase
  a
    color: $color-mute-350

.category__share
  position: absolute
  top: 0.5rem
  right: 0
