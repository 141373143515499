@import 'vars'

.episode__block__related_episode
  padding-top: 0
  .card
    h2 .content__category
      font-size: 1.2rem
    h2
      margin-bottom: 0 !important
    h3
      font-size: 1.6rem
      margin-top: 0
