@import 'vars'

// Animations

@keyframes pulse
  0%
    opacity: 1
  50%
    opacity: 0.1
  100%
    opacity: 1

@keyframes fade
  0%
    opacity: 0
  100%
    opacity: 1

@keyframes currentGrow
  0%
    width: 0
  100%
    width: calc(100% - 2rem)0px $color-contrast-a inset

@keyframes gradientMove
  0%
    background-position: 0% 50%
  50%
    background-position: 100% 50%
  100%
    background-position: 0% 50%

@keyframes pointer
  0%
    transform: translateY(1px) translateX(0px)
  50%
    transform: translateY(1px) translateX(5px)
  100%
    transform: translateY(1px) translateX(0px)

@keyframes grow
  0%
    width: 0
  100%
    width: 100%
