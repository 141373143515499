@import 'vars'

.plug__header
  margin: 5rem 0 3.8rem 0
  text-align: center
  h1, h2
    font-family: $decoFont
    font-weight: bold
    font-size: 5rem
    @media #{$mq_xs}
      font-size: 3.2rem
  @media #{$mq_xs}
    margin: 2rem 0 3rem 0

.plug__header--secondary
  margin: 9rem 0 3.4rem 0

.plug__header-icon
  img
    @media #{$mq_xs}
      height: auto
      width: 4.7rem

.plug__categories
  display: block
  column-count: 2
  column-gap: 3rem
  margin-bottom: 3rem
  @media #{$mq_xs}
    column-count: 1
    column-gap: 2rem
    margin-bottom: 2rem

.plug__category
  background: $color-primary
  padding: 4rem 7rem
  margin-bottom: 4rem
  break-inside: avoid
  @media #{$mq_xs}
    padding: 2.4rem
    margin-bottom: 2rem
  ul
    list-style: none
    padding: 0
    li
      a
        display: block
        color: $color-contrast-b
        font-size: 1.6rem
        border-bottom: 1px solid $color-mute-700
        margin-bottom: 2.5rem
        padding-bottom: 2.5rem
        @media #{$mq_xs}
          margin-bottom: 2rem
          padding-bottom: 2rem
      &:last-child
        a
          border-bottom: none
          margin-bottom: 0
          padding-bottom: 0

.plug__category-header
  text-align: center
  margin: 0 0 5rem 0
  h2
    font-size: 3.2rem
  @media #{$mq_xs}
    margin: 3.8rem 0 2.6rem 0
    h2
      font-size: 2.6rem

.plug__category-icon
  img
    width: 4.8rem
    height: auto
    @media #{$mq_xs}
      width: 3.5rem

.plug__wrap
  padding: 2rem 0 6rem 0

// CTAs

.plug__ctas
  display: flex
  .btn__cta
    margin: 0 1.8rem 0 0
  @media #{$mq_xs}
    margin-top: 3rem
    display: block
    .btn__cta
      margin: 0 0 1.8rem 0
      text-align: center
      width: 100%

// Plug Grids

%plugger-grid
  display: grid
  grid-gap: 3rem
  margin-bottom: 3rem
  @media #{$mq_xs}
    grid-template-columns: 1fr !important

.plugger-grid-1
  grid-template-columns: 1fr
  @extend %plugger-grid

.plugger-grid-2
  grid-template-columns: 1fr 1fr
  @extend %plugger-grid

.plugger-grid-3
  grid-template-columns: 1fr 1fr 1fr
  @extend %plugger-grid

// Plug Form / Fields

.plugger-form
  background: $color-primary
  max-width: 77rem
  margin: 6rem auto
  padding: 6rem 10rem 9rem 10rem
  position: relative
  h2
    font-size: 3.2rem
  &:after
    content: ' '
    position: absolute
    bottom: 2.6rem
    right: 3.8rem
    width: 4.6rem
    height: 4.8rem
    background: url('/img/drew-sig-lg.png') no-repeat
    background-size: 100%
  @media #{$mq_xs}
    margin: 3rem auto
    padding: 4rem 3rem 9rem 3rem

.plugger-form__desc
  margin-bottom: 3rem

.plugger-form__label
  width: 100%
  margin: 0 0 0.5rem 0

.plugger-fields
  grid-column-gap: 3rem
  grid-row-gap: 2rem
  font-size: 1.6rem
  input, select, textarea
    font-family: $bodyFont
    font-size: 1.6rem
    box-sizing: border-box
    background: $color-contrast-a
    width: 100%
    border-radius: 4px
    border: none
    padding: 1.5rem 1rem
  select
    -webkit-appearance: none
  a
    font-weight: bold
    color: $color-contrast-b
  @media #{$mq_xs}
    grid-column-gap: 1.3rem
    grid-row-gap: 2rem

.field__file
  margin-top: -2.5rem // Hack to suppress chrome validation error
  input
    transform: translate(20px, 20px)
    overflow: hidden
    height: 0
    appearance: none
    opacity: 0

.field__select
  width: 100%
  position: relative
  &:before
    content: ' '
    width: 2rem
    height: 2rem
    background: url('/img/icons/caret.svg') no-repeat
    position: absolute
    top: 50%
    right: 1rem
    transform: translateY(-50%)

.field__checkbox, .field__radio
  cursor: pointer
  input
    cursor: pointer
    width: 2rem
    height: 2rem
    margin: 0 10px 0 0
    appearance: none
    padding: 0
    background: $color-contrast-a
    &:checked
      border: 5px solid $color-contrast-a
      background: $color-contrast-b

.field__radio
  input
    border-radius: 50%
    border: 5px solid $color-contrast-a
    transform: translateY(4px)
    &:checked
      background: $color-contrast-b

.field__radio-option
  display: inline-block
  margin-right: 2rem

.field__checkbox
  display: flex
  justify-content: center
  align-items: flex-start
  font-size: 1.3rem

.field__file-btn
  display: flex
  justify-content: flex-start
  align-items: center
  width: 100%
  .btn--secondary
    max-width: 200px

.field__file-label
  margin-left: 1rem

.field__file-label-filled
  margin-top: 1rem

.field__maxwords
  margin-top: 0.3rem
  width: 100%
  text-align: right
  font-size: 1.3rem

.field__hidden-label
  margin-top: 2.1rem

// Error Notices

.plugger-notice
  margin: 0 0 3rem 0
  padding: 1rem 2rem
  border-radius: 4px
  color: $color-contrast-a
  background: $color-contrast-b

.plugger-notice--error
  color: $color-contrast-a
  background: $color-error

.plugger-fields
  input, select, textarea
    &:focus
      &:required
        &:invalid
          outline: none
          appearance: none
          border: 1px solid $color-error