@import 'vars'

.carousel-with-feature
  background: $color-primary
  padding: 3rem 0 0 0
  margin: 0
  h2
    font-size: 3.2rem
    font-weight: bold
    margin-bottom: 2rem
    text-transform: lowercase
    @media #{$mq_xs}
      font-size: 2.4rem
      margin-bottom: 1rem
  // Overrides
  .carousel-content
    padding: 3rem 0 4rem 0
    .card
      min-width: 27rem
  .carousel-content__arrow
    top: calc(50% - 3.2rem)
  @media #{$mq_xs}
    padding: 4rem 0 0 0
    margin: 0

.carousel-with-feature__grid
  display: grid
  grid-template-columns: 1.4fr 1fr
  grid-column-gap: 3rem
  @media #{$mq_xs}
    grid-row-gap: 2rem
    grid-template-columns: 1fr

.carousel-with-feature__img
  cursor: pointer
  &:hover
    .play-icon
      transform: translate(-50%, -50%) scale(1.2)

.carousel-with-feature__desc
  display: flex
  align-items: center
  padding: 0
  h3
    font-size: 3.2rem
    line-height: 1.19
    @media #{$mq_xs}
      line-height: 1.13

.carousel-with-feature__category
  margin: 1rem 0 3rem 0
  a
    color: $color-mute-300
    font-size: 1.6rem
    text-transform: uppercase
    font-weight: bold
  @media #{$mq_xs}
    margin: 1rem 0 2rem 0

.carousel-with-feature__body
  font-size: 1.6rem
  word-break: break-word
  @media #{$mq_xs}
    font-size: 1.8rem

// Overrides

.carousel-with-feature__bananamores
  background: $color-contrast-a

.carousel-with-feature__dark
  background: $color-mute-700
  h2
    color: $color-contrast-a
  .carousel-with-feature__desc
    color: $color-contrast-a
  .carousel-with-feature__icon
    svg
      fill: $color-contrast-a
  .card
    h3
      color: $color-contrast-a