@import 'vars'

.virtual-audience
  text-align: center
  background: $color-primary
  color: $color-mute-700
  padding: 5rem 3rem
  h2
    font-family: $decoFont
    font-weight: bold
    padding: 0
    margin: 0 0 1rem 0
    font-size: 5rem
    @media #{$mq_xs}
      font-size: 3.6rem
      line-height: 3.8rem
  p
    font-size: 1.6rem
    line-height: 2rem
    @media #{$mq_xs}
      font-size: 1.5rem
      line-height: 1.9rem
  @media #{$mq_xs}
    padding: 5rem 2rem 3rem 2rem

.virtual-audience__btn
  display: inline-block
  background: $color-secondary
  padding: 0.7rem 9rem
  margin: 2rem 0
  font-size: 2.4rem
  font-family: $decoFont
  font-weight: bold
  color: $color-contrast-a
  border-radius: 4px
  @media #{$mq_xs}
    font-size: 1.8rem

.virtual-audience__rules
  font-size: 1.3rem
  line-height: 1.6rem
  color: $color-mute-400
  @media #{$mq_xs}
    font-size: 1.2rem
    line-height: 1.5rem