// Helvetica Now

@import url("//hello.myfonts.net/count/3bb4f3")

@font-face
  font-display: swap
  font-family: 'HelveticaNow'
  font-weight: 400
  src: url('/fonts/HelveticaNowText-Regular/font.woff2') format('woff2'), url('/fonts/HelveticaNowText-Regular/font.woff') format('woff')

@font-face
  font-display: swap
  font-family: 'HelveticaNow'
  font-weight: 700
  font-style: bold
  src: url('/fonts/HelveticaNowText-Bold/font.woff2') format('woff2'), url('/fonts/HelveticaNowText-Bold/font.woff') format('woff')

@font-face
  font-display: swap
  font-family: 'HelveticaNowDisplay'
  font-weight: 700
  src: url('/fonts/HelveticaNowDisplay-Bd/font.woff2') format('woff2'), url('/fonts/HelveticaNowDisplay-Bd/font.woff') format('woff')

// CVP

@font-face
  font-family: 'ProximaNova-Regular'
  font-display: swap
  src: url('https://avia.vtg.paramount.tech/cvp/fonts/36A8CE_2_0.eot')
  src: url('https://avia.vtg.paramount.tech/cvp/fonts/36A8CE_2_0.eot?#iefix') format('embedded-opentype'), url('https://vidtech.cbsinteractive.com/cvp/fonts/36A8CE_2_0.woff2') format('woff2'), url('https://vidtech.cbsinteractive.com/cvp/fonts/36A8CE_2_0.woff') format('woff'), url('https://vidtech.cbsinteractive.com/cvp/fonts/36A8CE_2_0.ttf') format('truetype')
