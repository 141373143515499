@import 'vars'

.preview__bar
  position: absolute
  top: 1rem
  right: 1rem
  background: $color-primary
  color: $color-contrast-b
  padding: 1rem
  border-radius: 0.4rem
  font-size: 1.2rem
  transition: background $hoverTime
  z-index: 99999
  &:hover
    background: $color-tertiary