@import 'vars'

.most-popular
  position: relative
  z-index: 1
  margin: 5rem 0

.most-popular__container
  display: grid
  grid-template-columns: 1fr
  grid-column-gap: 1rem
  grid-row-gap: 3rem
  @media #{$mq_md}
    grid-template-columns: 1fr 1fr
    grid-column-gap: 3rem
  @media #{$mq_lg}
    grid-template-columns: 1fr 1fr 1fr
    grid-column-gap: 3rem