@import 'vars'

.gallery__header
  background: $color-contrast-a
  margin-bottom: 5rem
  @media #{$mq_xs}
    margin: 0 -2rem 4rem -2rem

.gallery__header-body
  padding: 2.5rem 20rem
  h2
    font-size: 3.2rem
  @media #{$mq_xs}
    padding: 2rem

.gallery__share
  position: absolute
  z-index: 2
  bottom: 2rem
  right: 2rem
  .share__social-btn
    svg
      fill: $color-contrast-a
    &:hover
      svg
        fill: darken($color-contrast-a, 10%)

.gallery__slide
  display: grid
  grid-template-columns: 2fr 4fr
  margin-bottom: 5rem
  @media #{$mq_xs}
    display: flex
    flex-direction: column-reverse
    margin: 0 -2rem 4rem -2rem

.gallery__slide-body
  position: relative
  padding: 3rem 3rem 6rem 3rem
  background: $color-contrast-a
  font-size: 1.6rem
  line-height: 2rem
  h2
    font-size: 3.2rem
  @media #{$mq_xs}
    padding: 2rem

.gallery__body-credit
  position: absolute
  bottom: 1rem
  width: calc(100% - 6rem)
  @media #{$mq_xs}
    width: 100%
    margin-top: 3rem
    bottom: 0
    position: relative

.gallery__slide-img
  position: relative
  background: $color-contrast-b
  display: flex
  justify-content: center
  align-items: center
  img
    width: 100%
    margin-bottom: -5px
  @media #{$mq_xs}
    padding: 0rem
    border-left: none

.gallery__count
  font-size: 1.8rem
  color: $color-mute-350
  font-weight: bold
  margin-bottom: 0.5rem

.gallery__credit
  font-size: 1.3rem
  padding: 0.5rem 0

// Lazy Load Blur
.lazy-load-image-background
  width: 100%

.lazy-load-image-background.blur
  filter: blur(15px)

.lazy-load-image-background.blur.lazy-load-image-loaded
  filter: blur(0)
  transition: filter .3s

.lazy-load-image-background.blur > img
  opacity: 0

.lazy-load-image-background.blur.lazy-load-image-loaded > img
  opacity: 1
  transition: opacity .3s
