@import 'vars'

.footer
  padding: $padding $padding $padding*3 $padding
  display: flex
  align-items: flex-start
  justify-content: space-between
  margin: 0
  background: $color-primary
  position: relative
  z-index: 1
  @media #{$mq_xs}
    display: flex
    flex-direction: column-reverse

.footer-menu
  ul
    margin: 0 3rem 0 0
    padding: 0
    list-style: none
    float: left
    li
      a
        font-size: 1.6rem
        line-height: 3.6rem
        display: block
        color: $color-mute-400
        margin: 0
        padding: 0 2rem 0 0
        transition: color $hoverTime
        &:hover
          color: $color-secondary
        @media #{$mq_xs}
          font-size: 1.6rem
          line-height: 2.4rem
      &:last-child
        a
          padding: 0
    @media #{$mq_xs}
      float: none

.footer-copy
  color: $color-mute-400
  padding: 0
  text-align: right
  font-size: 1.2rem
  @media #{$mq_xs}
    font-size: 1.2rem
    text-align: left
    margin-top: 2rem

.footer-right
  text-align: right

a.ot-sdk-show-settings
  cursor: pointer