@import 'vars'

.block-featured-comedians .block-schedule__content
  display: flex
  flex-direction: row-reverse
  padding: 0
  @media #{$mq_xs}
    display: block

.block-schedule__bio-image > img.block-schedule__img
  filter: grayscale(0)

.block-schedule__contact
  font-size: 2rem
  overflow-wrap: break-word

.block-schedule__bio-image
  flex: 2

.block-schedule__bio-info
  flex: 2
  padding: 2rem
