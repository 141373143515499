@import 'vars'

.social
  margin-top: 2rem
  list-style: none
  padding: 0
  display: flex
  justify-content: flex-end
  flex-wrap: wrap
  @media #{$mq_xs}
    margin: 0 0 2rem 0
    justify-content: flex-start
  li
    padding: 0.8rem 0.8rem 0.8rem 0
    &:first-child
      padding-left: 0
    &:last-child
      padding-right: 0
  svg
    width: 3.5rem
    height: 3.5rem
    fill: $color-contrast-a

.social--dark
  margin: 0
  justify-content: space-between
  svg
    transform: translateY(2px)
    width: 2rem
    height: 2rem
    fill: $color-contrast-b
  @media #{$mq_xs}
    margin: 0