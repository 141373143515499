@import 'vars'

.carousel-content__viewport
  overflow: visible

.carousel-content__container
  display: flex

.carousel-content
  position: relative
  z-index: 1
  padding: 5rem 0
  .card
    min-width: 46.9rem
    width: 46.9rem
    padding-right: 3rem
    @media #{$mq_xs}
      min-width: 27.5rem
      width: 27.5rem
      padding-right: 1rem

.carousel-content__heading
  font-size: 3.2rem
  text-align: left
  color: $color-mute-700
  font-family: $decoFont
  margin-bottom: 20px
  text-transform: lowercase
  @media #{$mq_xs}
    font-size: 2.4rem
    line-height: 4.6rem

.carousel-content__slideshow
  outline: none

// Transports

.carousel-content__arrow
  position: absolute
  top: calc(50% + 1rem) // Fudge to accommodate for the title height
  transform: translateY(-50%)
  z-index: 2
  background: none
  outline: none
  border: none
  cursor: pointer
  filter: drop-shadow(1px 1px 5px rgba($color-contrast-b, .9))
  svg
    height: 3.6rem
  @media #{$mq_xs}
    display: none

.carousel-content__arrow--next
  right: 2rem
  svg
    transform: rotate(180deg)

.carousel-content__arrow--prev
  left: 2rem
