@import 'vars'

.inf-section
  position: relative
  z-index: 1
  margin: 4rem 0 2rem 0
  h2
    font-size: 3.2rem
    margin-bottom: 2rem

.inf-section-caro
  .carousel-content
    padding: 2rem 0 4rem 0

.inf-section__grid
  display: grid
  grid-column-gap: 3rem
  grid-template-columns: 1fr 1fr 1fr 1fr
  a
    color: $color-contrast-b
    font-weight: 500

.inf-section__item
  cursor: pointer
  font-size: 1.6rem
  margin-bottom: 3.7rem
  h4
    display: flex
    margin-top: 0.5rem
    padding: 0
    font-family: $bodyFont
    font-size: 1.6rem
    line-height: 1.25
    font-weight: normal

.inf-section__img
  position: relative
  overflow: hidden

.inf-section__brand
  margin: 0.5rem 0
  font-size: 1.5rem
  color: $color-mute-250

.inf-section__caro-more-desktop
  .btn__cta
    margin: 2rem 0 8rem 0

.inf-section__caro-more
  transform: translateY(-1rem)
  cursor: pointer
  position: relative
  border: none
  padding-bottom: 53%
  border-radius: 4px
  min-width: 46.9rem
  margin: 0
  text-align: center
  transition: background $hoverTime, color $hoverTime
  background: $color-secondary
  color: $color-contrast-a
  &:hover
    background: $color-secondary
    color: $color-contrast-a
  @media #{$mq_xs}
    min-width: 27.5rem

.inf-section__caro-more-label
  font-size: 1.6rem
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%,-50%)
