@import 'vars'

.about-scroller
  background: $color-contrast-b
  text-align: center
  padding-bottom: 5rem

.about-bio
  padding: 5rem 0 0 0
  background: #F7F3F0
  position: relative
  overflow: hidden
  @media #{$mq_xs}

.about__content
  position: relative
  z-index: 1
  text-align: center
  color: $color-contrast-b
  h2
    font-family: $decoFont
    font-size: 7.4rem
    text-align: center
    width: 100%
    margin: 2rem 0
    @media #{$mq_xs}
      font-size: 3.8rem

.about__copy
  color: $color-contrast-a
  margin: 7.2rem 0 0 0
  p
    text-align: left
    font-size: 2rem
    line-height: 3rem
    @media #{$mq_xs}
      font-size: 1.8rem
      line-height: 2.7rem
  @media #{$mq_xs}
    margin: 3rem 0 0 0

.about__content-portrait
  margin: 6rem auto 2rem auto
  max-width: 80%
  height: auto

.about__hero-img
  bottom: 0
  height: auto
  left: 25%
  max-width: 100%
  position: absolute
  transform: translateX(-40%)
  z-index: 0
  @media #{$mq_xs}
    bottom: -.4rem
    left: 0
    max-width: none
    position: relative
    transform: translateX(0)
    width: 100%

.about__content-sig
  width: 100%
  margin: 0 0 6rem 0
  max-width: 26.4rem
  height: auto
  @media #{$mq_xs}
    max-width: 15.7rem

.about__content-panel-left
  grid-column: span 7
  @media #{$mq_xs}
    grid-column: span 12

.about__content-panel-right
  grid-column: span 5
  @media #{$mq_xs}
    grid-column: span 12