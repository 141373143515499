@import 'vars'

.connect
  text-align: center
  background: $color-secondary
  font-size: 2rem
  padding: 2rem 0
  color: $color-contrast-a
  h2
    font-weight: 600
    text-transform: uppercase
    font-size: 2rem
    line-height: 2.4rem
    margin: 2rem 0
  .social
    justify-content: center
    margin: 0
    li
      padding: 0 1rem
    svg
      width: 4rem