@import 'vars'

.recipe__grid
  display: grid
  grid-template-columns: repeat(12, 1fr)
  grid-column-gap: 3rem
  @media #{$mq_xs}
    display: block

.recipe__grid-content
  grid-column: span 7

.recipe__grid-aside
  grid-column: span 5
  position: relative

.recipe__summary
  font-size: 3rem
  line-height: 4.4rem
  margin-bottom: 4rem

// Durations

.recipe__durations
  position: relative
  padding: 5rem 0
  background: $color-primary
  font-size: 3.2rem
  font-weight: bold
  text-align: left
  @media #{$mq_xs}
    padding: 4rem 0
    font-size: 3rem
    line-height: 3.2rem
  span
    margin-top: 1rem
    font-weight: normal
    font-size: 1.6rem
    display: block
    text-transform: uppercase
    @media #{$mq_xs}
      font-size: 1.2rem
  hr
    background: $color-mute-375
    height: 1px
    width: calc(100% - 3rem)
    border: 0
  &:before
    position: absolute
    top: 0
    right: -2rem
    background: $color-primary
    content: ' '
    height: 100%
    width: 2rem
    display: none
    @media #{$mq_xs}
      display: block
  &:after
    position: absolute
    top: 0
    left: -50vw
    background: $color-primary
    content: ' '
    height: 100%
    width: 50vw
    @media #{$mq_xs}
      left: -2rem
      width: 2.1rem

.recipe__durations-grid
  padding-right: 2rem
  display: grid
  grid-template-columns: 1fr 1fr

// Aside

.recipe-share
  text-align: right
  border-bottom: 1px solid $color-mute-375
  margin: 1rem 0
  padding-bottom: 1rem
  @media #{$mq_xs}
    text-align: left
    span
      display: inline

.recipe__btn-related
  border-bottom: 1px solid $color-mute-375
  display: block
  padding: 0 0 2rem 0
  h3
    font-size: 2rem
    font-weight: normal
  a
    color: $color-contrast-b
    border-bottom: 4px solid $color-primary
    line-height: 1.2rem

.recipe-video-mobile
  margin-bottom: 3rem
  h3
    text-align: center
    font-size: 1.8rem

// Ingredients

.recipe__ingredients
  background: $color-contrast-a
  position: relative
  padding: 5rem 0
  font-size: 1.6rem
  font-weight: normal
  margin-bottom: 3rem
  @media #{$mq_xs}
    padding: 4rem 0
  ul
    padding-left: 2rem
    li
      margin-bottom: 1rem
  h4
    font-size: 3.2rem
    margin-bottom: 2rem
  &:before
    position: absolute
    top: 0
    right: -2rem
    background: $color-contrast-a
    content: ' '
    height: 100%
    width: 2.1rem
    display: none
    @media #{$mq_xs}
      display: block
  &:after
    position: absolute
    top: 0
    left: -50vw
    background: $color-contrast-a
    content: ' '
    height: 100%
    width: 50vw

// Instructions

$olSize: 3rem
.recipe__instructions
  h4
    font-size: 3.2rem
    margin-bottom: 4rem
  ol
    counter-reset: counter
    list-style: none
    padding-left: 4rem
    >li
      margin: 0 0 2rem 0
      counter-increment: counter
      position: relative
      &:before
        content: counter(counter)
        color: $color-contrast-b
        font-size: 1.5rem
        font-weight: bold
        position: absolute
        left: calc(-1 * #{$olSize} - 1rem)
        line-height: $olSize
        width: $olSize
        height: $olSize
        top: -0.5rem
        border-radius: 50%
        border: 1px solid $color-contrast-b
        text-align: center
        background: none

// Related Recipes

.recipes__related
  position: relative
  background: $color-primary
  padding: 3rem 0
  h3
    padding: 0
    margin: 0 0 3rem 0
    color: $color-contrast-b

.recipes__related-bg
  position: relative
  z-index: 0
  pointer-events: none
  margin-bottom: -0.5rem
  border-bottom: 4px solid $color-contrast-a
  width: 100%
  height: auto

.recipes__related-cta
  font-family: $decoFont
  padding: 1.5rem
  border-radius: 4px
  display: inline-flex
  justify-content: flex-start
  align-items: center
  position: absolute
  bottom: 3rem
  z-index: 1
  background: $color-contrast-a
  font-size: 3.2rem
  line-height: 3.4rem
  font-weight: bold
  color: $color-contrast-b
  svg
    width: 4rem
    height: 4rem
    margin-right: 1rem
  @media #{$mq_xs}
    font-size: 2.2rem
    line-height: 2.4rem
