@import 'vars'

.vertical
  display: flex
  flex-flow: column
  max-width: 116.8rem
  margin: 0 auto
  @media #{$mq-lg}
    flex-flow: row wrap
    flex-direction: row-reverse
  .header--wrapper
    display: flex
    flex-direction: column
    align-self: center
    margin-top: 2rem
    padding: 0 2rem
    @media #{$mq-lg}
      flex-basis: 50%
      margin-top: 5rem
    .header--image
      align-self: center
      margin-bottom: 3rem
      img
        align-self: center
        width: 19rem
        height: 19rem
        border-radius: 50%
    h1
      text-align: center
      margin-bottom: 3rem
      font-size: 3rem
    .share
      align-self: center
      margin-bottom: 2.5rem
  .secondary--image
    line-height: 0
    @media #{$mq-lg}
      flex-basis: 50%

.episode__block__related_episode
  padding-top: 0
  .card
    h2 .content__category
      font-size: 1.2rem
    h2
      margin-bottom: 0 !important
    h3
      font-size: 1.6rem
      margin-top: 0