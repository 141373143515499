@import 'vars'

.see-all__viewport
  overflow: visible

.see-all__container
  display: grid
  grid-template-columns: 1fr 1fr 1fr 1fr
  grid-column-gap: 3rem
  @media #{$mq_xs}
    grid-column-gap: 1rem

.see-all
  position: relative
  z-index: 1
  margin: 4rem 0

.see-all__heading
  display: flex
  justify-content: space-between
  align-items: center
  font-size: 3.2rem
  text-align: left
  color: $color-mute-700
  font-family: $decoFont
  margin-bottom: 20px
  line-height: 1
  @media #{$mq_xs}
    font-size: 2.4rem
    line-height: 1

.see-all__cta
  font-size: 1.6rem
  color: $color-mute-700
  font-weight: bold
  display: flex
  justify-content: center
  align-items: center
  transition: color $hoverTime
  white-space: nowrap
  svg
    margin-left: 6px
    transform: translateY(1px)
    transition: stroke $hoverTime
  &:hover
    color: $color-secondary
    svg
      animation: pointer 1s infinite
      path
        stroke: $color-secondary
