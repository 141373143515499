@import 'vars'

.submenu
  list-style: none
  margin: 0
  padding: 0 0 3rem 0
  width: 100%
  li
    display: inline-block
    margin: 0 1rem 1rem 0
    &.visuallyhidden
      display: none
    &:last-child
      margin-right: 0
    a, button
      cursor: pointer
      outline: none
      border: none
      display: block
      font-size: 1.6rem
      color: $color-mute-400
      background: $color-contrast-a
      border-radius: 4px
      font-weight: bold
      text-transform: lowercase
      padding: 0.8rem 1.5rem
      transition: color $hoverTime, background $hoverTime
      line-height: 1
      &:hover
        background: $color-mute-700
        color: $color-contrast-a
      &.submenu__see-all
        color: $color-contrast-a
        background: $color-tertiary
        &:hover
          background: $color-secondary
          color: $color-contrast-a
