@import 'vars'

// Default Tag Overrides

.ctd-body
  h1, h2, h3, h4, p
    margin: 0 0 2rem 0
    line-height: 1.5
  ul, ol
    li
      padding: 0 0 1rem 0

// Speedbump

.shortcode-speedbump
  margin: 3rem 0
  a
    color: $color-contrast-b !important
    font-weight: bold !important
    text-decoration: none !important
    display: block

// Video

.shortcode-video
  margin-bottom: 2rem

// YouTube

.shortcode-youtube
  position: relative
  padding-bottom: 56.25%
  height: 0
  iframe
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%

// Facebook

.shortcode-facebook
  text-align: center

// Twitter

.shortcode-twitter
  .twitter-tweet
    display: flex
    align-items: center
    justify-content: center
    text-align: center
    margin: 0 auto
    iframe
      margin: 0 auto
      width: 100% !important

// Image

.shortcode-image
  margin: 2rem 0
  img
    width: 100%
  figcaption
    text-align: center
    font-size: 1.3rem
    color: $color-mute-400

// Animated Gifs

.shortcode-animatedgif
  text-align: center

// Listicles

.shortcode-listicle
  background-color: #f7b719
  padding: 4rem
  @media #{$mq_lg}
    padding: 5rem

  .listicle-header
    h2
      font-family: $decoFont
      font-size: 2rem
      margin-bottom: 0
      line-height: 1.25
    p
      font-size: 1.4rem
    @media #{$mq_md}
      display: none

  .listicle-button
    a
      text-decoration: none !important
    h3
      padding-bottom: 0 !important
      font-weight: 600 !important

  .listicle-button-body
    img
      width: 100%
      height: 100%
      max-width: 275px
      @media #{$mq_md}
        max-width: 240px
    @media #{$mq_md}
      display: flex
      align-items: center
      > div
        flex: 0 50%

  .brand-listicle
    font-size: 1.3rem
    position: relative
    bottom: 7px
    display: block
    @media #{$mq_md}
      display: inline-block

  .btn--listicle
    color: #fff !important
    padding: 1.1rem 2rem
    border-radius: 4px
    background-color: #fd005b

  .listicle-link
    @media #{$mq_xs}
      display: block
    @media #{$mq_md}
      display: none
    @media #{$mq_lg}
    display: none

  .listicle-button-title
    font-size: 1.3rem

  .listicle-header--desktop
    display: none
    @media #{$mq_md}
      display: block
