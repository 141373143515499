@import 'vars'

.placed
  position: relative

.placed-elem
  position: absolute
  top: 0
  left: 0
  opacity: 0
  z-index: 0
  animation: fade 0.4s forwards

.placed-275x154
  background: $color-mute-200
  padding-bottom: 56%

.placed-469x265
  background: $color-mute-200
  padding-bottom: 56%

.placed-50
  background: $color-mute-200
  padding-bottom: 100%

