@import 'vars'

.carousel-featured
  position: relative
  overflow: hidden
  background: url(/img/bg_bananamores.png)
  @media #{$mq_xs}
    .constrain
      padding: 0

.carousel-featured__viewport
  overflow: hidden

.carousel-featured__container
  display: flex

.carousel-featured__grid
  display: grid
  grid-template-columns: 1.4fr 1fr
  grid-column-gap: 2.8rem
  @media #{$mq_xs}
    grid-column-gap: 0
    grid-template-columns: 1fr

.carousel-featured__slide
  width: 100%
  flex-shrink: 0

.carousel-featured__cta
  cursor: pointer
  display: block
  position: relative
  img
    margin-bottom: -4px
  &:hover
    .play-icon
      transform: translate(-50%, -50%) scale(1.2)

.carousel-featured__slide-copy
  padding: 4rem 0 5rem 0
  .category-grid
    margin: 1.2rem 0 0 0
  h2
    font-family: $decoFont
    font-size: 3.2rem
    line-height: 1.2
    margin: 0
    padding: 0
    @media #{$mq_xs}
      font-size: 2.8rem
  p
    margin: 2.5rem 0 0 0
    font-size: 1.6rem
    line-height: 1.2
  @media #{$mq_xs}
    padding: 4rem 2rem 8rem 2rem

.carousel-featured__img
  width: 100%
  height: auto

// Pager

.carousel-featured__pager
  position: absolute
  bottom: 3rem
  left: 50%
  transform: translateX(-50%)
  z-index: 2
  list-style: none
  padding: 0
  width: 100%
  // max-width: 116.8rem
  display: flex
  button
    border: none
    cursor: pointer
    background: rgba(#E4E4E4, 0.3)
    position: relative
    display: block
    margin-right: 3rem
    flex-grow: 1
    flex-basis: 0
    border-radius: 4px
    height: 0.4rem
    @media #{$mq_xs}
      margin-right: 1rem
    &.pager-active
      overflow: hidden
      &:before
        content: ' '
        position: absolute
        width: 100%
        height: 20px
        top: 0
        left: 0
        background: $color-contrast-a
        animation: grow 10s
      &.pager-active--no-anim
        &:before
          animation: none
  @media #{$mq_xs}
    padding: 2rem
    bottom: 2rem


