@import 'vars'

.block-videos
  padding: 5rem 0
  text-align: center
  @media #{$mq_xs}
    padding: 3rem 0

.block-videos__heading
  font-size: 3.2rem
  text-align: left
  color: $color-mute-700
  font-family: $decoFont
  margin-bottom: 2rem
  @media #{$mq_xs}
    font-size: 2.4rem
    line-height: 4.6rem
    margin-bottom: 1.5rem

.block-videos__grid
  display: grid
  grid-template-columns: 1fr 1fr
  grid-gap: 3rem
  @media #{$mq_xs}
    grid-gap: 2rem
    grid-template-columns: 1fr

.block-videos__item
  position: relative
  overflow: hidden
  h2
    text-align: left
    color: $color-contrast-a
    position: absolute
    z-index: 2
    bottom: 2rem
    left: 2rem
    width: 80%

.block-videos__duration
  position: absolute
  bottom: 1rem
  padding: 0.25rem 0.75rem
  right: 1rem
  z-index: 2
  color: $color-contrast-a
  background: rgba(51, 51, 51, 0.7)
  font-size: 1.3rem
  border-radius: 4px

.block-videos__mask
  position: absolute
  bottom: 0
  left: 0
  z-index: 1
  height: 18.8rem
  width: 100%
  background: linear-gradient(to bottom, rgba($color-contrast-b, 0) 0%, rgba($color-contrast-b, 0.7))
  transition: height $hoverTime
