@import 'vars'

.galleries__header
  margin: 3rem 0
  text-align: center
  h1
    background: $color-contrast-a
    border-radius: 4px
    display: inline-block
    padding: 1rem 2.5rem
    font-size: 2rem

.galleries__header-icon
  display: flex
  margin: 0 auto 1rem auto
  justify-content: center
  align-items: center
  width: 7.2rem
  height: 7.2rem
  border-radius: 50%
  background: $color-primary
  svg
    width: 3.6rem
    height: 3.6rem
    fill: $color-contrast-a
