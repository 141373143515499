@import 'vars'

.share
  position: relative
  display: inline-block

.share__btn
  background: none
  border: none
  transition: background $hoverTime
  cursor: pointer
  display: inline-flex
  justify-content: flex-start
  align-items: center
  color: $color-contrast-b
  svg
    margin-left: 1rem
    width: 2.4rem
    height: 2.4rem
    fill: $color-contrast-b
  span
    @media #{$mq_xs}
      display: none

.share__social-btn
  padding: 0
  border: none
  background: none
  display: inline-flex
  align-items: center
  justify-content: center
  margin: 0 0 0 1.2rem
  transition: background $hoverTime
  cursor: pointer
  svg
    padding: 0.2rem
    width: 3.2rem
    height: 3.2rem
    fill: $color-mute-700
    transition: fill $hoverTime
  &:hover
    svg
      fill: $color-secondary
