@import 'vars'

.sticky
  .stuck-close
    display: none

.stuck
  position: fixed
  bottom: 1rem
  right: 1rem
  width: 500px
  .stuck-close
    display: block
  @media #{$mq_xs}
    position: relative
    width: auto
    bottom: auto
    right: auto
    .stuck-close
      display: none

.stuck-close
  width: 2rem
  height: 2rem
  position: absolute
  top: 0.5rem
  right: 0.5rem
  z-index: 100
  cursor: pointer
  border: none
  padding: 0
  background: none
  svg
    fill: $color-contrast-a

.stuck-placeholder
  padding-bottom: 56.25%
  background: $color-mute-700
  @media #{$mq_xs}
    display: none