@import 'vars'

*
  box-sizing: border-box
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  font-display: fallback
  outline-color: $color-primary

html
  font-size: 10px // base rem
  min-height: 100vh

body
  margin: 0
  padding: 0
  font-family: $bodyFont
  overflow-x: hidden
  background: $color-mute-50
  color: $color-contrast-b

h1, h2, h3, h4, h5
  font-family: $decoFont
  margin: 0
  padding: 0

hr
  background: #929292
  height: 1px
  border: 0px

p
  margin: 0
  padding: 0

a
  text-decoration: none
  color: $color-primary

::selection
  background: $color-primary
  color: $color-contrast-b

// https://css-tricks.com/snippets/css/change-autocomplete-styles-webkit-browsers/

input:-webkit-autofill
  border: none
  -webkit-text-fill-color: $color-contrast-b
  -webkit-box-shadow: 0 0 0px 1000px $color-contrast-a inset
  &:hover, &:focus
    border: none
    -webkit-text-fill-color: $color-contrast-b
    -webkit-box-shadow: 0 0 0px 1000px $color-contrast-a inset
