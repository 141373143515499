@import 'vars'

.caro-rec
  position: relative
  z-index: 1
  padding: 2rem 0
  background: $color-contrast-a

.caro-rec__viewport
  overflow: hidden
  margin: 0 5rem
  @media #{$mq_xs}
    margin: 0

.caro-rec__container
  display: flex
  justify-content: flex-start
  align-items: flex-start

// Transports

.caro-rec__arrow
  position: absolute
  top: 50px
  transform: translateY(-50%)
  z-index: 2
  background: none
  outline: none
  border: none
  cursor: pointer
  filter: drop-shadow(1px 1px 5px rgba($color-contrast-a, .9))
  svg
    height: 3.6rem
    path
      fill: $color-mute-400
  @media #{$mq_xs}
    display: none

.caro-rec__arrow--next
  right: 0.5rem
  svg
    transform: rotate(180deg)

.caro-rec__arrow--prev
  left: 0.5rem

// Recomendation

.caro-rec__recomendation
  position: relative
  margin-right: 3rem
  min-width: 15.8rem
  width: 15.8rem
  background: none
  border: none
  padding: 0
  text-align: left
  cursor: pointer
  appearance: none
  h4
    margin-top: 1.1rem
    color: $color-mute-700
    font-size: 1.3rem
    line-height: 1.6rem
    font-family: $bodyFont
    font-weight: normal
  @media #{$mq_xs}
    margin-right: 1.4rem

.caro-rec__recomendation-img
  width: 100%
  position: relative
  img
    width: 15.8rem

// Badges

.caro-rec__badge
  position: absolute
  bottom: 1.5rem
  padding: 0.25rem 0.75rem
  left: 1rem
  z-index: 2
  background: rgba(51, 51, 51, 0.7)
  font-size: 1.3rem
  border-radius: 4px
  font-weight: bold
  text-transform: lowercase

.caro-rec__nextup
  background: $color-contrast-a
  color: $color-contrast-b

.caro-rec__nowplaying
  background: $color-tertiary
  color: $color-contrast-a