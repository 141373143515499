@import 'vars'

.btn
  padding: 1rem 2rem
  display: block
  width: 100%
  font-size: 1.8rem
  font-weight: bold
  cursor: pointer
  text-align: center
  background: none
  color: $color-contrast-a
  @media #{$mq_xs}
    padding: 1.6rem 3rem

.btn--load-more
  margin: $padding 0 0 0
  border: 1px solid $color-contrast-a
  text-transform: uppercase
  padding: 1.6rem 4.8rem
  transition: background $hoverTime, color $hoverTime, border-color $hoverTime
  &:hover
    color: $color-primary
    border-color: $color-primary

.btn--dark
  color: $color-contrast-b
  border: 1px solid $color-contrast-b
  &:hover
    color: $color-contrast-b
    border-color: $color-secondary

.btn--secondary
  color: $color-contrast-a
  background: $color-mute-400
  border-radius: 4px
  display: inline-flex
  justify-content: flex-start
  align-items: center
  svg
    margin: 0 0.5rem -4px 0
  &:hover
    color: $color-contrast-a
    background: $color-mute-400

.btn__cta
  border: none
  padding: 1rem 4.8rem
  background: $color-secondary
  color: $color-contrast-a
  font-weight: bold
  font-size: 2.4rem
  line-height: 1
  font-family: $decoFont
  border-radius: 4px
  margin: 4.5rem 0 0 0
  cursor: pointer
  display: inline-flex
  justify-content: center
  align-items: center
  transition: background $hoverTime, color $hoverTime
  baseline-shift: 10%
  img
    margin-right: 0.5rem
  span
    display: inline-block
    margin-top: -3px
  @media #{$mq_xs}
    margin: 3rem 0 0 0
  &:hover
    background: $color-tertiary

.btn__cta--with-icon
  padding: 1rem 3rem 1rem 2rem
  position: relative
  display: flex
  justify-content: center
  align-items: center
  div, span
    display: inline-block
  svg
    height: 2.5rem
    transform: translateY(0.5rem)
    margin-right: 0.5rem
    display: inline-block
    fill: $color-contrast-a

.btn__cta--muted
  background: $color-mute-200
  color: $color-contrast-b
  svg
    fill: $color-contrast-b

.btn__cta--contrast
  background: $color-contrast-a
  color: $color-contrast-b

.btn__cta--ghost
  background: none
  border: 2px solid $color-secondary
  color: $color-secondary
  &:hover
    color: $color-contrast-a
    background: $color-tertiary

// Buy Now Buttons

a.button--ecommerce-link
  @extend .btn__cta
  margin: 0
  font-size: 1.8rem
  color: $color-contrast-a !important // Override body link def
  border-bottom: none !important // Override body link def
