// This is the main entry point for global styles
@import 'vars'
@import 'redefined'

@import 'about'
@import 'ads'
@import 'animations'
@import 'avia'
@import 'blockCard'
@import 'blockFeatured'
@import 'blockFeaturedComedians'
@import 'blockHeroVideo'
@import 'blockScheduled'
@import 'blockSeeMore'
@import 'blockVideos'
@import 'bodyParser'
@import 'button'
@import 'card'
@import 'carouselContent'
@import 'carouselEpisodes'
@import 'carouselFeatured'
@import 'carouselHero'
@import 'carouselWithFeature'
@import 'category'
@import 'connectWithDrew'
@import 'content'
@import 'contentHub'
@import 'cvp'
@import 'episode'
@import 'episodeSchedule'
@import 'error'
@import 'fonts'
@import 'footer'
@import 'galleries'
@import 'gallery'
@import 'header'
@import 'imageHero'
@import 'infiniteSection'
@import 'input'
@import 'loader'
@import 'locationList'
@import 'mostPopular'
@import 'newsletterSignup'
@import 'nextUp'
@import 'parallaxHeader'
@import 'parallaxImage'
@import 'placeholder'
@import 'plugger'
@import 'previewBar'
@import 'recipe'
@import 'recommendationsCarousel'
@import 'search'
@import 'sectionHero'
@import 'seeAll'
@import 'share'
@import 'social'
@import 'stick'
@import 'subMenu'
@import 'taxonomy'
@import 'textScroller'
@import 'vertical'
@import 'videoPreview'
@import 'virtualAudience'
@import 'whereToWatch'

// Utils

.spacer
  display: block

.spacer--2
  height: 2rem

.spacer--3
  height: 3rem

.img-responsive
  max-width: 100%
  height: auto

.img-full
  width: 100%
  height: auto

.text-center
  text-align: center

.center
  margin: 0 auto

.flex-right
  align-items: flex-end !important

.width-100
  width: 100%

.error
  background: $color-error
  border-radius: 1rem
  font-size: 1.4rem
  padding: 1rem
  margin: 1rem

.mobile-only
  display: none
  @media #{$mq_xs}
    display: block

.desktop-only
  display: block
  @media #{$mq_xs}
    display: none

.visuallyhidden
  display: none

.plx-full
  width: 100%

.sort-above
  z-index: 1

.place-relative
  position: relative

.btn-reset
  -moz-osx-font-smoothing: inherit
  -webkit-appearance: none
  -webkit-font-smoothing: inherit
  background: none
  border: none
  color: inherit
  cursor: pointer
  font: inherit
  line-height: inherit
  margin: 0
  overflow: visible
  padding: 0
  text-align: inherit
  width: inherit

.hidden-link
  color: $color-contrast-b
  cursor: pointer

.no-top-margin
  margin-top: 0

.no-bottom-margin
  margin-bottom: 0

.no-padding
  padding: 0 !important

// Loader / GQL Messages

.loading
  padding: 4rem
  text-align: center
  font-size: 2rem
  min-height: calc(100vh - 340px)
  display: flex
  align-items: center
  justify-content: center

.msg
  padding: 4rem
  text-align: center
  font-size: 2rem
  color: $color-contrast-a

.msg--dark
  color: $color-contrast-b

// Wrappers

.wrap-relative
  position: relative

.wrap-site
  position: relative
  margin: 0 auto
  min-height: calc(100vh - 31.2rem) // Sticky footer in pinch

.btf
  display: block
  clear: left

.constrain
  position: relative
  max-width: 116.8rem
  margin: 0 auto
  padding: 0 2rem
  @media #{$mq_lg}
    padding: 0

.constrain--static
  position: static

.constrain--nopadd
  padding: 0

.site-bg
  background: $color-mute-50
  position: relative
  z-index: 1

.x-hidden
  overflow-x: hidden

.wrap-bg-a
  background: $color-contrast-a

.wrap-bg-primary
  background: $color-primary

.wrap-bg-b
  background: $color-contrast-b

.wrap-bg-polka
  background: url('/img/bg_bananamores.png')

// Grid

.grid
  display: grid
  grid-template-columns: repeat(12, 1fr)

@for $i from 1 through 12
  .grid-span-#{$i}
    grid-column: span #{$i}
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
  .grid-xs-span-#{$i}
    @media #{$mq_xs}
      grid-column: span #{$i}


// Prevent Transition Flash

#preload *
  transition: none !important
  -webkit-transition: none !important
  -moz-transition: none !important
  -ms-transition: none !important
  -o-transition: none !important

// Common

.play-icon
  position: absolute
  z-index: 3
  top: 50%
  left: 50%
  transition: transform $hoverTime
  transform: translate(-50%, -50%)
