@import 'vars'

.banner-wheretowatch
  text-align: center
  padding: 5rem $padding
  margin: 0
  background-size: cover
  background-position: center center
  .search-wrap
    margin: 0 auto
    max-width: 50rem
  .search-button
    span
      margin-top: 0.2rem
    @media #{$mq_xs}
      padding-top: 1.2rem

.banner-wheretowatch__desc
  color: $color-contrast-a
  padding: $padding
  h2
    font-size: 4.8rem
    font-weight: 800
  @media #{$mq_xs}
    padding: 1rem

.banner-wheretowatch__desc-body
  font-size: 1.6rem
