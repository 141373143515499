@import 'vars'

.search-wrap
  position: absolute
  top: 0
  left: 50%
  transform: translate(-50%, -6rem)
  display: flex
  justify-content: center
  margin-top: 2rem
  width: 52.2rem
  @media #{$mq_xs}
    transform: translate(-50%, -4rem)
    width: 25rem

.search
  position: relative
  display: inline-flex
  justify-content: flex-start
  align-items: center
  margin-right: 1rem
  background: $color-contrast-a
  padding: 0 1rem
  border-radius: 4px
  width: 100%
  height: 8rem
  input
    width: calc(100% - 19.5rem)
    font-size: 2.6rem
    padding: 1.1rem
    border: 0
    outline: none
  @media #{$mq_xs}
    height: 4rem
    padding: 0 0.4rem
    input
      font-size: 1.6rem
      width: calc(100% - 3.4rem)

.search-button
  padding: 1.2rem 1rem
  display: inline-flex
  justify-content: center
  align-items: center
  background: $color-tertiary
  width: 19.5rem
  height: 6.8rem
  font-size: 3rem
  font-weight: bold
  text-transform: lowercase
  border-radius: 4px
  border: none
  color: $color-contrast-a
  @media #{$mq_xs}
    padding: 0rem 1rem
    font-size: 1.87rem
    height: 3.4rem
    width: 9.5rem