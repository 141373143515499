// Colors
$color-primary: #f8b715
$color-secondary: #FD005B // Hover color
$color-offset: #ebad14
$color-tertiary: #E80054
$color-quaternary: #ff94d1
$color-quinary: #acdbf7

$color-mute-25: #a2a2a2
$color-mute-50: #F6F3EF
$color-mute-100: #f1f1f1
$color-mute-200: #dcdcdc
$color-mute-250: #EEE
$color-mute-300: #595959
$color-mute-350: #929292
$color-mute-375: #979797
$color-mute-400: #333333
$color-mute-500: #636363
$color-mute-600: #444444
$color-mute-700: #111111
$color-mute-800: #888
$color-mute-850: #757575

$color-contrast-a: #fff
$color-contrast-b: #000
$color-error: #A60000

// Fonts
$bodyFont: 'HelveticaNow', sans-serif
$decoFont: 'HelveticaNowDisplay', sans-serif

// Breaks
// usage: @media #{$mq_xs}
$mq_xs: 'screen and (max-width: 1200px)'
$mq_md: 'screen and (min-width: 768px)'
$mq_lg: 'screen and (min-width: 1200px)'

// Timings
$hoverTime: 0.2s

// Global
$padding: 3rem
$sm_padding: 1.5rem
$containerWidth: 100%
