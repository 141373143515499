@import 'vars'

$animTime: 0.3s

header
  position: absolute
  z-index: 4
  top: 0
  left: 0
  width: 100%

.main-menu__interior
  position: relative
  height: 8.8rem
  @media #{$mq_xs}
    height: 7rem
  .main-menu
    position: relative
  .main-menu__logo__circle
    display: none
  .main-menu__secondary
    transform: translateX(0)
    opacity: 1
    pointer-events: all

.main-menu
  position: absolute
  display: flex
  top: 0
  left: 0
  z-index: 4
  padding: $padding

.main-menu__toggle
  position: absolute
  z-index: 9
  top: 3rem
  right: -7rem
  background: none
  border: none
  outline: none
  cursor: pointer
  transition: right $animTime
  &.main-menu__toggle--primary
    position: absolute
    top: 3rem
    left: 3rem
    width: 50px
    @media #{$mq_xs}
      left: 2rem
      top: 1.1rem
      background: $color-secondary
      padding: 1rem 0.8rem 0.8rem 0.8rem
      border-radius: 4px
  &.main-menu__toggle--sidebar
    top: 3.5rem
    right: 3.3rem
    svg
      width: 2.1rem
      height: 2.1rem
      path
        fill: $color-contrast-a
  svg
    overflow: visible
    width: 3.3rem
    height: 2.7rem
    @media #{$mq_xs}
      width: 2.8rem
    path
      fill: $color-contrast-a
      transition: transform $animTime, opacity $animTime, fill $animTime
    path:nth-child(1)
      transform-origin: 0 9px
    path:nth-child(3)
      transform-origin: 33px 9px
  &.main-menu__toggle--toggled
    right: 1.8rem
    svg
      path:nth-child(1)
        fill: $color-contrast-b
        transform-origin: 0 9px
        transform: rotate(45deg)
      path:nth-child(2)
        opacity: 0
      path:nth-child(3)
        fill: $color-contrast-b
        transform-origin: 33px 9px
        transform: rotate(-45deg)
  @media #{$mq_xs}
    right: -6rem

.main-menu__logo
  overflow: hidden
  position: absolute
  z-index: 1
  top: 0
  left: 9.2rem
  cursor: pointer
  width: 20.9rem
  transition: width $animTime*2, height $animTime*2, transform $animTime*2
  svg
    width: 100%
  &.main-menu__logo--toggled
    transform: translateY(-15rem)
  @media #{$mq_xs}
    left: auto
    right: 1.6rem
    width: 13.5rem

.main-menu__logo__circle
  cursor: pointer
  display: flex
  align-items: center
  justify-content: center
  background: $color-primary
  height: 13.5rem
  width: 13.5rem
  border-radius: 50%
  padding: 2rem 1.15rem 1.15rem
  transition: transform $animTime*2, opacity $animTime*2
  opacity: 1
  transform: translateY(-1rem)
  @media #{$mq-lg}
    display: none
  svg
    height: 7rem
    width: 10.4rem
    @media #{$mq-lg}
      height: 10rem
      width: 16rem

.main-menu__logo__circle--toggled
  opacity: 0
  transform: translateY(-13.5rem)

.main-menu__cta
  position: absolute
  z-index: 9
  top: 2rem
  right: 9.5rem
  text-transform: lowercase
  text-align: center
  font-size: 2.4rem
  line-height: 1.8rem
  font-weight: 700
  font-family: $decoFont
  display: inline-flex
  justify-content: center
  align-items: flex-start
  color: $color-contrast-a
  background: $color-secondary
  padding: 1.4rem 2rem
  transition: background $hoverTime
  cursor: pointer
  border-radius: 4px
  border: none
  div
    display: inline
  svg
    transform: translateY(0.1rem)
    margin: 0 2rem 0 0
    width: 2rem
    height: 2rem
    fill: $color-contrast-a
  &:hover
    background: $color-tertiary
  @media #{$mq_xs}
    display: none

.main-menu__search
  @extend .main-menu__cta
  right: 3rem
  padding: 1.4rem
  svg
    margin: 0
  @media #{$mq_xs}
    top: 1.1rem
    right: auto
    left: 8rem
    display: block
  @supports (-webkit-touch-callout: none)
    top: 2rem
    @media #{$mq_xs}
      top: 1.3rem

// Secondary Header

.main-menu__secondary
  background: $color-primary
  height: 8.8rem
  width: 100vw
  position: absolute // fixed
  z-index: 8
  transform: translateY(-8.9rem)
  display: flex
  opacity: 0
  pointer-events: none
  transition: transform $animTime*2, opacity $animTime*2
  padding: 0 3rem 0 10rem
  justify-content: space-between
  align-items: center
  &.main-menu__secondary--toggled
    opacity: 1
    pointer-events: all
    transform: translateX(0)
  a
    cursor: pointer
  svg
    margin-top: 3px
    width: 104px
    height: 70px
  @media #{$mq_xs}
    height: 7rem
    justify-content: flex-end
    svg
      margin-top: 3px
      width: 82px
      height: 55px

// Sidebar
$sidebarWidth: 50.5rem

.main-menu__logo--sidebar
  width: 10.4rem
  height: 10.4rem
  top: 2rem
  left: 3.3rem
  @media #{$mq_xs}
    left: 2rem

.main-menu__logo-sidebar
  cursor: pointer
  svg
    margin-top: 0.8rem

.main-menu__sidebar
  z-index: 101
  padding: 0
  position: fixed
  top: 0
  transform: translateX(-$sidebarWidth)
  width: $sidebarWidth
  background: $color-mute-700
  transition: transform $animTime*2
  height: 100vh
  &.main-menu__sidebar--toggled
    transform: translateX(0)
  @media #{$mq_xs}
    width: 35.5rem
    transform: translateX(-35.5rem)

.main-menu__sidebar-scroll-region
  width: 100%
  height: 100vh

.main-menu__sidebar-scroll-region__thumb-v
  background: $color-primary
  border-radius: 5px

.main-menu__sidebar-spacer
  height: 13rem

.main-menu__sidebar-section
  border-bottom: 1px solid $color-mute-400
  padding: 0 3.3rem 2rem 3.3rem
  @media #{$mq_xs}
    padding: 0 2rem 2rem 2rem

.main-menu__sidebar-section--above
  padding: 2rem 2rem 0 2rem

.main-menu__sidebar-section-menu
  padding: 0
  margin: 0
  list-style: none
  li
    padding: 0
    a,
    button
      line-height: 2rem
      display: flex
      align-items: center
      justify-content: flex-start
      color: $color-contrast-a
      font-weight: bold
      font-size: 2rem
      margin-bottom: 1rem
      padding: 0.1rem 0 0.6rem
      div
        display: inline-flex
        align-items: center
        justify-content: center
        width: 2.5rem
        svg
          margin: 0 0 -0.5rem 0
      span
        padding-top: 0.6rem
        padding-bottom: 0.4rem
        margin-left: 1.5rem
        margin-top: 0.5rem
        border-bottom: 0.4rem solid transparent
        cursor: pointer
      &:hover
        span
          display: block
          text-decoration: none
          border-bottom: 0.4rem solid $color-primary
    button
      border: none
      background-color: transparent
  .main-menu__sidebar--subnav
    .main-menu__submenu
      margin-top: 1.5rem
      padding-bottom: 1rem
      border-bottom: 1px solid $color-mute-400
    li
      padding-bottom: 1rem
      list-style: none
      a, button
        line-height: 1.2rem
        font-size: 1.6rem
        margin-bottom: 0

.main-menu__sidebar-section--supplemental
  padding-top: 1rem
  padding-right: 4rem
  @media #{$mq_xs}
    padding-right: 2.5rem
  li
    a, button
      font-weight: normal

.main-menu__sidebar-section--content
  border-bottom: none
  h2
    color: $color-contrast-a
    font-size: 2rem
    font-weight: bold
    margin: 0 0 2rem 0

// Helpers

.main-menu__sidebar-mask
  z-index: 100
  opacity: 0
  border: none
  position: fixed
  top: 0
  bottom: 0
  transform: translateX(-120%)
  width: 120%
  outline: none
  background: rgba($color-contrast-b, 0.3)
  background: linear-gradient(to right, rgba($color-contrast-b, 0.3) 0%,rgba($color-contrast-b, 0.3) 90%,rgba(0,0,0,0) 100%)
  cursor: pointer
  transition: opacity $animTime*2, transform $animTime*2
  &.main-menu__sidebar-mask--toggled
    transform: translateX(0)
    opacity: 1

.main-menu__sticky
  z-index: 98

// Content Hubs

.main-menu__hubs
  display: grid
  grid-template-columns: 1fr 1fr
  h3
    font-size: 2.2rem
    line-height: 1
    margin: 0 0 1.5rem 0
    @media #{$mq_xs}
      font-size: 2rem
  a
    text-transform: lowercase
    color: $color-contrast-a
    transition: color $hoverTime
    &:hover
      color: $color-primary
  nav
    border-bottom: 1px solid $color-mute-400
    padding: 3rem
    @media #{$mq_xs}
      padding: 2rem
    &:nth-child(odd)
      border-right: 1px solid $color-mute-400
  ul
    margin: 0
    padding: 0
    list-style: none
    li
      margin-bottom: 1.2rem
      &:last-child
        margin-bottom: 0
      a
        font-size: 1.6rem
        line-height: 1
        color: $color-mute-50
        @media #{$mq_xs}
          font-size: 1.3rem


