@import 'vars'

.hero-video
  padding: 8rem 0 7rem 0
  @media #{$mq_xs}
    padding: 5rem 0 3rem 0

.hero-video__grid
  display: grid
  grid-template-columns: 1fr 1.4fr
  grid-column-gap: 1rem
  @media #{$mq_xs}
    grid-template-columns: 1fr

.hero-video__img
  position: relative
  cursor: pointer
  &:hover
    .play-icon
      transform: translate(-50%, -50%) scale(1.2)
  @media #{$mq_xs}
    width: calc(100% + 4rem)
    margin-left: -2rem

.hero-video__logo
  img
    width: 100%
  @media #{$mq_xs}
    max-width: 29.1rem
    margin: 0 auto 2.6rem auto

.hero-video__desc
  display: flex
  margin-top: 1rem
  h1
    padding: 0 1rem
    flex-grow: 2
    font-family: $bodyFont
    font-weight: normal
    font-size: 1.6rem

.hero-video__desc-social
  // Override
  .share
    display: flex
    svg
      margin-left: 0.7rem
      fill: $color-contrast-b
      width: 2.8rem
      height: 2.8rem
    .share__social-btn
      margin: 0
      &:hover
        svg
          fill: $color-secondary

// Overrides (Assigned via CMS)

.hero-video__bananamores
  background: url('/img/bg_bananamores.png')