@import 'vars'

.text-scroller
  font-weight: bold
  font-family: $decoFont
  font-size: 6rem
  line-height: 6.5rem
  margin: 0 auto
  color: $color-primary
  user-select: none
  @media #{$mq_xs}
    font-size: 2.7rem
    line-height: 2.7rem

.text-scroller__text
  position: relative
  transition: opacity 1s

.text-scroller__text-left
  position: absolute
  top: 12px
  left: -265px
  opacity: 0
  transition: opacity 1s
  @media #{$mq_xs}
    top: 7px
    left: -122px

.text-scroller__text-right
  position: absolute
  top: 12px
  right: -245px
  opacity: 0
  transition: opacity 1s
  @media #{$mq_xs}
    top: 7px
    right: -112px

.text-scroller__text-center
  color: $color-contrast-a

.text-scroller__text--active
  .text-scroller__text-center
    color: $color-contrast-a
  .text-scroller__text-left
    opacity: 1
  .text-scroller__text-right
    opacity: 1
