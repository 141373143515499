@import 'vars'

.block-card
  background: $color-contrast-b
  color: $color-contrast-a
  padding: 7rem 0 6rem 0
  @media #{$mq_xs}
    padding: 7rem 0 4rem 0

.block-card__grid
  display: grid
  grid-template-columns: 1fr 3fr
  @media #{$mq_xs}
    grid-template-columns: 1fr

.block-card__logo
  @media #{$mq_xs}
    padding: 0 6rem 4rem 6rem

.block-card__desc
  text-align: center
  h2
    font-size: 5rem
    font-weight: bold
    line-height: 0.76
    text-align: center
    margin: 0 0 1rem 0
    @media #{$mq_xs}
      font-size: 3rem
  h3
    font-size: 1.6rem
    line-height: 1.25
    margin: 0 0 3rem 0
    font-family: $bodyFont
    font-weight: normal
    @media #{$mq_xs}
      padding: 0 1rem
  // Override
  .btn__cta
    margin: 1rem auto

.block-card__body
  display: inline-block
  margin: 0 auto
  text-align: left
  font-size: 1.6rem
  a
    color: $color-contrast-a
    font-weight: bold
    text-decoration: none
    border-bottom: 4px solid $color-primary
    line-height: 1.2rem
    display: inline-block
  ol,ul
    margin: 0
    padding: 0 0 0 2em
    li
      line-height: 1.25
      font-weight: bold
      margin-bottom: 1rem

.block-card__cta
  @media #{$mq_xs}
    margin: 0 auto
    max-width: 24rem

.block-card__disclaimer
  font-size: 1.3rem
  line-height: 1.23
  color: $color-mute-25
