@import 'vars'

.taxonomy__header
  font-size: 4rem
  line-height: 4rem
  margin: 0 0 2rem 0
  padding: 0
  @media #{$mq_xs}
    font-size: 3rem
    line-height: 3rem

.taxonomy__results
  margin-top: 3rem
  font-size: 1.1rem

.taxonomy__item
  overflow: hidden
  display: flex
  width: 100%
  border-bottom: 1px solid $color-mute-200
  margin-bottom: 2rem
  padding-bottom: 2rem
  @media #{$mq_xs}
    display: block

.taxonomy__item-desc
  padding: 3rem
  width: 50%
  display: flex
  align-items: center
  h3
    color: $color-contrast-b
    font-size: 2rem
    margin-bottom: 0.5rem
  h4
    font-size: 1.6rem
    color: $color-mute-350
  @media #{$mq_xs}
    width: 100%
    padding: 2rem 2rem 0 2rem

.taxonomy__item-body
  color: $color-contrast-b
  font-size: 1.6rem
  margin: 2rem 0

.taxonomy__item-category
  font-size: 1.6rem
  line-height: 2rem
  font-weight: bold
  color: $color-mute-350
  text-transform: uppercase
  margin-top: 1rem
  @media #{$mq_xs}
    margin-bottom: 2rem

.taxonomy__item-img
  position: relative
  img
    margin-bottom: -4px

.taxonomy__item-icon
  position: absolute
  right: 0
  bottom: 0
  background: $color-primary
  padding: 0.5rem
  border-radius: 1rem 0 0 0
  width: 4rem
  height: 4rem
  svg
    width: 100%
    transform: translateY(1px)

