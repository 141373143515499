@import 'vars'

.carousel-hero
  position: relative
  margin-bottom: -0.5rem
  overflow: hidden
  transform: translateZ(0) scale(1) // Force to a composite layer

.carousel-hero__viewport
  overflow: hidden

.carousel-hero__container
  display: flex

.carousel-hero__slideshow
  outline: none

.carousel-hero__slide
  cursor: pointer
  width: 100%
  min-width: 100%
  color: $color-contrast-a
  position: relative
  z-index: 4
  display: block
  overflow: hidden
  margin-bottom: -1rem

.carousel-hero__slide-copy
  position: absolute
  z-index: 40
  left: 50%
  bottom: 7rem
  max-width: 116.8rem
  width: 100%
  display: flex
  align-items: center
  justify-content: flex-end
  transform: translateX(-50%)
  h2
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5)
    text-align: right
    width: 50%
    font-family: $decoFont
    font-size: 5.6rem
    line-height: 5.8rem
    @media #{$mq_xs}
      font-size: 3.5rem
      line-height: 3.7rem
      width: 100%
  @media #{$mq_xs}
    padding: 2rem
    bottom: 5rem

.carousel-hero__img
  position: relative
  z-index: 1
  width: 100%
  height: auto

// Pager

.carousel-hero__pager
  position: absolute
  bottom: 3rem
  left: 50%
  transform: translateX(-50%)
  z-index: 2
  list-style: none
  padding: 0
  width: 100%
  max-width: 116.8rem
  display: flex
  button
    border: none
    cursor: pointer
    background: rgba(#E4E4E4, 0.3)
    position: relative
    display: block
    margin-right: 3rem
    flex-grow: 1
    flex-basis: 0
    border-radius: 4px
    height: 0.4rem
    @media #{$mq_xs}
      margin-right: 1rem
    &:last-child
      margin-right: 0
    &.pager-active
      overflow: hidden
      &:before
        content: ' '
        position: absolute
        width: 100%
        height: 20px
        top: 0
        left: 0
        background: $color-contrast-a
        animation: grow 10s
      &.pager-active--no-anim
        &:before
          animation: none
  @media #{$mq_xs}
    padding: 2rem
    bottom: 2rem

// Mask

.carousel-hero__slide-mask
  z-index: 1
  pointer-events: none
  position: absolute
  bottom: 0
  width: 100%
  height: 21.2rem
  background: linear-gradient(to bottom, rgba($color-contrast-b, 0), rgba($color-contrast-b, 0.5))

// Video

.carousel-hero__slide-video
  position: absolute
  top: 50%
  left: 50%
  z-index: 2
  transform: translate(-50%, -50%)
  width: 100%
  height: 100%
  video
    width: 100%
    @media #{$mq_xs}
      object-fit: cover
      height: 100%
      width: 100%
