@import 'vars'

.content-hub__hero
  position: relative
  text-align: center
  background: $color-mute-700
  h1
    font-size: 5.6rem
    font-family: $decoFont
    font-weight: bold
    color: $color-contrast-a
    margin-bottom: 3rem
    line-height: 1
    @media #{$mq_xs}
      font-size: 3.5rem
  img
    width: 100%
    height: auto
    position: relative
    z-index: 0
    margin-bottom: -3px

.content-hub__hero-desc
  position: relative
  width: 100%
  padding: 0 3rem
  z-index: 2
  margin-top: -17rem
  @media #{$mq_xs}
    padding: 0 1rem

.content-hub__hero-img
  position: relative
  &:before
    content: ' '
    background-image: linear-gradient(to bottom, rgba(39, 39, 39, 0.5), rgba(39, 39, 39, 0) 45%, rgba(26, 26, 26, 0.14) 65%, rgba($color-mute-700, 1))
    position: absolute
    z-index: 1
    width: 100%
    height: 100%
    bottom: 0
    left: 0
