@import 'vars'

.cvp
  position: relative
  z-index: 1
  height: 0
  padding-bottom: 56.25%
  margin: 0 auto
  .cvp__player
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
