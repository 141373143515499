@import 'vars'

.avia
  position: relative
  z-index: 1
  width: 100%
  padding-bottom: 56.25%
  margin: 0 auto

.avia__player
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  [data-role='adContainer']
    left: 0

// Override

.cvui-time-display
  font-size: 1.6rem

avia-unmute-cta
  top: 10px !important
  left: 10px !important
  avia-button
    font-size: 1.6rem
    text-transform: uppercase
    background-position: -3.4rem center
    @media #{$mq_lg}
      font-size: 1.8rem
      background-position: -4rem center