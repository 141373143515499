@import 'vars'

.card
  position: relative
  &:last-child
    margin-right: 0
  h3
    display: flex
    margin-top: 1rem
    color: $color-mute-700
    font-size: 1.6rem
    font-weight: normal
    font-family: $bodyFont
    line-height: 2rem
    min-height: 4rem // Prevent a layout shift for multiline titles
    text-align: left
    @media #{$mq_xs}
      margin-top: 1rem

.card__type-icon
  margin: -0.4rem 1rem 0 0

.card__type-icon--dark
  svg
    fill: $color-contrast-b

.card__img
  width: 100%
  height: auto

// Cards with Numbers

.card__withnumber-block
  position: relative
  display: grid
  grid-template-columns: 0.6fr 2fr
  width: 100%

.card__withnumber-img
  position: relative

@for $i from 1 through 6
  .card__number--#{$i}
    background: url('/img/numbers/#{$i}.png') top left no-repeat
    background-size: auto 97.5%

// Cards with Interior Titles

.card-interior
  position: relative
  h2
    font-size: 2rem
    line-height: 2rem
    position: absolute
    z-index: 2
    left: 2rem
    bottom: 2rem
    display: flex
    justify-content: flex-start
    align-items: center
    color: $color-contrast-b
    text-align: left
    padding: 0.5rem 2rem 0.5rem 0.5rem
    background: $color-contrast-a
    border-radius: 4px
    max-width: calc(100% - 4rem)
    transition: color $hoverTime, background $hoverTime
  &:hover
    h2
      color: $color-contrast-a
      background: $color-contrast-b
      .card-interior__type-icon
        svg
          fill: $color-contrast-a
    &.card-interior--inline
      h2
        color: $color-contrast-b
        background: none

.card-interior__img
  width: 100%
  height: auto
  margin-bottom: -3px

.card-interior__mask
  position: absolute
  bottom: 0
  left: 0
  z-index: 1
  height: 18.8rem
  width: 100%
  background: linear-gradient(to bottom, rgba($color-contrast-b, 0) 0%, rgba($color-contrast-b, 0.7))
  transition: height $hoverTime

.card-interior__type-icon
  margin: 0 0.5rem 0 0
  padding: 1rem
  width: 4.3rem
  height: 4.3rem
  display: flex
  justify-content: center
  align-items: center
  border-radius: 50%
  svg
    margin-bottom: -0.4rem
    transition: fill $hoverTime
    fill: $color-contrast-b

// Inline Override

.card-interior--inline
  h2
    background: none
    bottom: 0
    left: 0
    position: relative
    max-width: 100%
    line-height: 1.3