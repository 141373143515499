@import 'vars'

.block-schedule
  padding: 9rem 0
  @media #{$mq_xs}
    padding: 3rem 0

.block-schedule__heading
  font-size: 5rem
  color: $color-mute-700
  text-align: center
  @media #{$mq_xs}
    font-size: 3rem
    margin-bottom: 2rem

.block-schedule__grid
  background: $color-primary
  display: grid
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr
  margin-bottom: -2px

.block-schedule__item
  position: relative
  padding: 0
  margin: 0
  cursor: pointer
  border: none
  overflow: hidden
  outline: none
  width: 100%

.block-schedule__item-mobile-label
  display: none
  text-align: center
  font-size: 1.5rem
  margin-bottom: 0.5rem
  @media #{$mq_xs}
    display: block

.block-schedule__overlay
  position: absolute
  border-bottom: 4px solid rgba(51, 51, 51, 0.59)
  left: 0
  top: 0
  height: 100%
  width: 100%
  background: linear-gradient(to bottom, rgba(51, 51, 51 ,0) 50%,rgba(51, 51, 51, 0.59) 100%)
  h3
    width: 100%
    font-size: 2rem
    position: absolute
    bottom: 0.5rem
    left: 50%
    transform: translateX(-50%)
    color: $color-contrast-a
    @media #{$mq_xs}
      display: none

.block-schedule__overlay--active
  border: 4px solid $color-secondary
  background: linear-gradient(to bottom, rgba(51, 51, 51, 0) 65%, rgba(51, 51, 51, 0.59))
  @media #{$mq_xs}
    border: 3px solid $color-secondary
    background: none

.block-schedule__img
  width: 100%
  margin-bottom: -6px
  transition: filter 1s
  filter: grayscale(1)
  height: auto

.block-schedule__img--active
  filter: grayscale(0)

.block-schedule__episode
  display: flex
  @media #{$mq_xs}
    flex-direction: column-reverse

.block-schedule__content
  padding: 4rem
  background: $color-contrast-a
  width: 100%
  max-height: 100%
  font-size: 1.6rem
  text-align: center
  h2
    font-size: 3.2rem
    margin: 0 0 1rem 0
  ul
    padding: 0
    list-style: none
    li
      font-weight: bold
      font-size: 2rem
  @media #{$mq_xs}
    padding: 2rem

.block-schedule__guests
  margin-top: 1px
  overflow: hidden
  position: relative
  min-width: 44.5rem
  overflow: hidden
  padding-bottom: 40%
  background: $color-contrast-a
  @media #{$mq_xs}
    padding-bottom: 100%
    width: 100%
    min-width: auto

.block-schedule__guest
  position: absolute
  z-index: 0
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  height: 100%
  opacity: 0
  transition: opacity $hoverTime
  img
    position: relative
    z-index: 2
    width: auto
    height: 100%
  h5
    color: $color-contrast-a
    font-size: 2rem
    position: absolute
    z-index: 4
    bottom: 4rem
    left: 50%
    transform: translate(-50%, -50%)
    @media #{$mq_xs}
      bottom: 3rem
      font-size: 1.9rem

.block-schedule__guest-overlay
  position: absolute
  top: 0
  left: 0
  z-index: 3
  height: 100%
  width: 100%
  background-image: linear-gradient(to bottom, rgba($color-contrast-b, 0) 53%, rgba($color-contrast-b, 0.3) 96%)

.block-schedule__guest--active
  opacity: 1

.block-schedule__guest-transports
  position: absolute
  bottom: 2rem
  left: 50%
  transform: translate(-50%)

.block-schedule__guest-transport
  width: 1.2rem
  height: 1.2rem
  border: 1px solid $color-contrast-a
  background: none
  margin: 0.2rem
  border-radius: 50%
  cursor: pointer
  padding: 0
  @media #{$mq_xs}
    width: 0.8rem
    height: 0.8rem

.block-schedule__guest-transport--active
  background: rgba($color-contrast-a, 0.7)