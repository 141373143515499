@import 'vars'

.cvui-up-next-panel
  background: rgba(79, 76, 82, 0.75)
  bottom: 73px
  cursor: pointer
  font-family: ProximaNova-Regular, serif
  height: 90px !important
  left: unset !important
  position: absolute
  right: 16px
  top: unset !important
  width: 230px !important
  z-index: 40
  border-radius: 4px

.cvui-up-next-row
  display: flex

.cvui-up-next-thumb
  flex: 1 1 44%
  height: 58px
  overflow: hidden
  margin: 5px

.cvui-up-next-block
  flex: 1 1 50%

.cvui-up-next-thumb img
  width: 110px

.cvui-up-next-heading
  color: $color-contrast-a
  font-weight: bold
  font-size: 14px
  line-height: 18px
  padding: 2px 5px
  background: $color-secondary
  width: 100%
  border-radius: 4px 4px 0 0

.cvui-up-next-title
  color: $color-contrast-a
  font-size: 10px
  line-height: 14px
  padding: 5px

.cvui-up-next-duration
  background: rgba($color-contrast-b, 0.75)
  bottom: 5px
  color: white
  font-size: 12px
  height: 20px
  line-height: 10px
  padding: 5px
  position: absolute
  right: 5px