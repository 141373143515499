@import 'vars'

.locations
  position: relative
  margin: 0 11.2rem
  padding: 6rem 0 0 0
  @media #{$mq_xs}
    padding: 2rem 0 0 0
    margin: 0 2rem

.locations__content
  color: $color-contrast-a
  position: relative
  z-index: 1
  font-size: 2rem
  padding: 0 0 1rem 0
  border-bottom: 1px solid $color-contrast-a

.locations__row
  display: grid
  grid-template-columns: 1fr 1fr 1fr
  grid-column-gap: $padding / 2
  border-top: none
  margin: 0
  padding: 0
  .cell
    padding: $padding / 2
  h4
    color: $color-primary
    display: none
    font-size: 1.2rem
    text-transform: uppercase
  @media #{$mq_xs}
    display: block
    border-top: 1px solid $color-mute-200
    margin: 1rem
    padding: 1rem 0
    h4
      display: block
    .cell
      padding: 1rem 0

.locations__row--header
  display: grid
  font-weight: bold
  text-transform: uppercase
  color: $color-primary
  margin: 0 $padding / 2
  div
    border-bottom: 1px solid $color-mute-500
    margin-bottom: 2rem
  .cell
    padding-left: 0
  @media #{$mq_xs}
    display: none

.locations__state
  color: $color-primary
  background: $color-mute-600
  border-radius: 4px
  padding: $padding / 2
  @media #{$mq_xs}
    color: $color-contrast-a
    font-size: 2.6rem
    padding: 0 0 0 $padding / 2
    background: none