@import 'vars'

.carousel-episode
  position: relative
  z-index: 1
  padding: 3rem 0
  background: $color-mute-700
  img
    border: 4px solid transparent
  .card
    cursor: pointer
    background: none
    border: none
    padding: 0
    width: 22.9rem
    min-width: 22.9rem
    padding-right: 0.5rem
    h3
      font-size: 2.4rem
      margin: 0.5rem 0
      color: $color-contrast-a
    @media #{$mq_xs}
      width: 27.5rem
      min-width: 27.5rem
      padding-right: 1rem

.carousel-episode__viewport
  overflow: visible

.carousel-episode__container
  display: flex

.carousel-episode__heading
  font-size: 3.2rem
  text-align: left
  color: $color-contrast-a
  font-family: $decoFont
  margin-bottom: 0rem
  @media #{$mq_xs}
    font-size: 3rem
    line-height: 2.9rem

.carousel-episode__subheading
  font-size: 1.6rem
  line-height: 2rem
  text-align: left
  color: $color-mute-50
  font-family: $decoFont
  margin-bottom: 20px
  @media #{$mq_xs}
    font-size: 1.8rem

.carousel-episode__slideshow
  outline: none

// Transports

.carousel-episode__arrow
  display: none // @TODO, these are a bit buggy skipping for now
  position: absolute
  top: calc(50% + 1rem) // Fudge to accommodate for the title height
  transform: translateY(-50%)
  z-index: 2
  background: none
  outline: none
  border: none
  cursor: pointer
  svg
    fill: $color-contrast-a
  @media #{$mq_xs}
    display: none

.carousel-episode__arrow--next
  right: -4em
  svg
    transform: rotate(180deg)

.carousel-episode__arrow--prev
  left: -4em

.carousel-episode__active
  position: relative
  img
    border: 4px solid $color-secondary
    box-sizing: border-box
  &:after
    width: 7rem
    height: 7rem
    transform: translate(-50%)
    content: ' '
    position: absolute
    bottom: -20%
    left: 50%
    background: $color-mute-50
    clip-path: polygon(50% 70%, 0 100%, 100% 100%)