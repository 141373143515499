.image-hero
  max-height: 80rem
  overflow: hidden
  position: relative

.image-hero__bg-image
  width: 100%
  height: auto
  @media (min-width: 800px) and (max-width: 1199px)
    transform: translateY(-7%)

.image-hero__links
  position: absolute
  bottom: 1.6rem
  width: 100%
  display: flex
  flex-wrap: wrap
  justify-content: center
  @media #{$mq_lg}
    bottom: 2.6rem

.image-hero__link
  display: inline-block
  padding: 1rem 2.2rem
  background-color: $color-secondary
  border-radius: 0.4rem
  margin: 0.65rem 1rem
  transition: background-color $hoverTime
  &:hover
    background-color: $color-tertiary
  @media #{$mq_lg}
    padding: 1.3rem 4rem

.image-hero__link-text
  height: 1.8rem
  width: auto
  @media #{$mq_lg}
    height: 2rem

.follow-drew
  background-color: $color-primary
  padding: 1.7rem 2rem 1.1rem
  @media #{$mq_lg}
    display: flex
    justify-content: center
    padding: 4.4rem 2rem 3rem

.follow-drew__text
  color: white
  font-size: 2rem
  text-align: center
  margin-bottom: 1rem
  @media #{$mq_lg}
    font-size: 2.4rem
    margin-left: 1rem
    margin-right: 1.5rem

.follow-drew__social-links
  display: flex
  flex-wrap: wrap
  justify-content: center

.follow-drew__social-link
  margin: 0.9rem
  height: 100%

.follow-drew__social-link-img
  height: 3.8rem
  width: 3.8rem
  @media #{$mq_lg}
    height: 4.2rem
    width: 4.2rem
