@import 'vars'

.stick
  position: sticky
  top: 0
  background: $color-mute-250
  z-index: 99

.stick-bottom
  position: fixed
  bottom: 0
  left: 0
  width: 100%
  background: $color-mute-250
  z-index: 99

.bidbarrel-ad
  text-align: center
  iframe
    margin: 1.2rem 0

.homepage-blocks .bidbarrel-ad
  &[data-ad-ref="leader-plus-flex-top"],
  &[data-ad-ref="mobile-banner-top"]
    background-color: $color-primary

.body-ad
  margin: 1rem 0
  width: 100vw
  transform: translateX(calc((50% - (77rem / 2)) * -1))
  @media #{$mq_xs}
    width: auto
    transform: none
