@import 'vars'

.newsletter
  text-align: center
  background: $color-primary
  color: $color-mute-700
  padding: 5rem 3rem 8rem 3rem
  h2
    font-family: $decoFont
    font-weight: bold
    padding: 0
    margin: 0 0 1rem 0
    font-size: 5rem
    @media #{$mq_xs}
      font-size: 3.6rem
      line-height: 3.8rem
  p
    font-size: 1.5rem
    line-height: 2rem
    @media #{$mq_xs}
      font-size: 1.5rem
      line-height: 1.9rem
  @media #{$mq_xs}
    padding: 5rem 2rem 3rem 2rem

.newsletter__input
  max-width: 70%
  display: flex
  margin: 2rem auto
  align-items: center
  align-content: flex-start
  @media #{$mq_xs}
    max-width: 100%

.newsletter__input-text
  width: 70%
  border: none
  border-radius: 4px
  font-size: 2.4rem
  padding: 1.1rem 2rem
  @media #{$mq_xs}
    font-size: 1.8rem

.newsletter_frm-submit
  cursor: pointer
  width: 30%
  border: none
  display: inline-block
  background: $color-secondary
  padding: 0.7rem 2rem
  margin: 0 0 0 1rem
  font-size: 2.4rem
  font-family: $decoFont
  font-weight: bold
  color: $color-contrast-a
  border-radius: 4px
  white-space: nowrap
  @media #{$mq_xs}
    font-size: 1.8rem

.newsletter__notice
  padding: 3rem
  display: flex
  align-items: center
  justify-content: center
  color: $color-contrast-b
  font-size: 2rem
  svg
    margin-right: 1rem

.newsletter__rules
  width: 70%
  margin: 0 auto
  font-size: 1.3rem
  line-height: 1.6rem
  color: $color-mute-400
  text-align: left
  a
    font-weight: bold
    color: $color-mute-400
  @media #{$mq_xs}
    width: 100%
    font-size: 1.2rem
    line-height: 1.5rem

.newsletter_frm-checkbox
  display: flex
  cursor: pointer

.newsletter_frm-checkbox-copy
  padding-left: 1rem