@import 'vars'

.block-featured
  padding: 5rem 0
  text-align: center

.block-featured__heading
  font-size: 3.2rem
  text-align: left
  color: $color-mute-700
  font-family: $decoFont
  margin-bottom: 20px
  @media #{$mq_xs}
    font-size: 2.4rem
    line-height: 4.6rem

.block-featured__category
  font-size: 1.6rem
  line-height: 2rem
  font-weight: bold
  color: $color-mute-350
  text-transform: uppercase
  margin-bottom: 3rem
  @media #{$mq_xs}
    margin-bottom: 2rem

.block-featured__feature
  display: grid
  grid-template-columns: repeat(12, 1fr)
  margin-bottom: 6.5rem
  @media #{$mq_xs}
    grid-template-columns: 1fr
    margin-bottom: 6.5rem

.block-featured__feature-image
  grid-column: span 7

.block-featured__feature-body
  grid-column: span 5
  padding: 3rem 0 3rem 3rem
  text-align: left
  display: flex
  justify-content: flex-start
  align-items: center
  h2
    font-size: 3.2rem
    line-height: 4rem
    margin-bottom: 2.5rem
  h3
    font-size: 3rem
    line-height: 3.2rem
  p
    font-size: 1.6rem
  @media #{$mq_xs}
    padding: 3rem 0 0 0

.block-featured__grid
  display: grid
  grid-template-columns: 1fr 1fr
  column-gap: 3rem
  .card
    margin-bottom: 2rem
  @media #{$mq_xs}
    grid-template-columns: 1fr

