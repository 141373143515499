@import 'vars'

.block-see-more
  padding: 5rem 0
  text-align: center

.block-see-more__heading
  text-align: center
  margin-bottom: 3rem
  h3
    font-family: $decoFont
    font-size: 5rem
    color: $color-mute-700

.block-see-more__grid
  display: grid
  grid-template-columns: 1fr 1fr
  grid-gap: 3rem
  button
    border: none
    background: none
  @media #{$mq_xs}
    grid-template-columns: 1fr
