@import 'vars'

.parallax-image
  position: relative
  z-index: 1
  overflow: hidden
  width: 100vw
  margin: 2rem 0 2rem 50%
  transform: translate(-50%)

.parallax-image__img
  @media #{$mq_xs}
    margin: 0