@import 'vars'

// Bar

.search-bar
  position: relative
  margin-bottom: 2rem
  input
    border: none
    border-radius: 4px
    font-size: 1.6rem
    width: 100%
    padding: 1rem
    background: $color-mute-600
    color: $color-contrast-a
    &::placeholder
      color: #838383

.search-bar__icon
  position: absolute
  top: 0.55rem
  right: 0.3rem
  border: none
  background: none
  cursor: pointer
  outline: none
  svg
    g
      stroke: $color-contrast-a

.search-bar__clear
  position: absolute
  top: 0.9rem
  right: 0.8rem
  border: none
  background: $color-mute-50
  border-radius: 50%
  cursor: pointer
  outline: none
  width: 3rem
  height: 3rem
  svg
    transform: translateY(2.5px)

.search-bar--page
  margin-bottom: 0
  input
    width: 38.2rem
    background: $color-contrast-a
    color: $color-contrast-b
    padding: 1.5rem 1.5rem 1.5rem 4rem
    &::placeholder
      color: #838383
  .search-bar__icon
    top: 1.2rem
    right: auto
    left: 0.5rem
    svg
      g
        stroke: $color-contrast-b
  @media #{$mq_xs}
    width: 100%
    input
      width: 100%

.search-bar__btn
  height: 100%
  border: none
  border-radius: 4px
  margin-left: 2rem
  background: $color-secondary
  color: $color-contrast-a
  font-weight: bold
  font-size: 2.4rem
  padding: 0 4rem
  cursor: pointer
  @media #{$mq_xs}
    display: none

.search-bar__grid
  display: inline-flex
  margin: 0 auto
  @media #{$mq_xs}
    display: block
    width: 100%

// Page

.search__header
  padding: 2rem 0 8rem 0
  text-align: center
  background: $color-primary
  h1
    font-family: $decoFont
    font-size: 5rem
    margin-bottom: 2rem
    line-height: 1.09
  @media #{$mq_xs}
    padding: 2rem 0 4rem 0
    h1
      font-size: 3.5rem

// Count

.search__count
  font-size: 1.6rem
  margin-bottom: 3rem

// Filters

.search__filters
  margin: 3rem 0
  padding: 0 0 2rem 0
  border-bottom: 1px solid $color-mute-375
  overflow-x: scroll
  background: none
  &::-webkit-scrollbar
    width: 0
    background: transparent
  ul
    list-style: none
    margin: 0
    padding: 0
    display: flex
    justify-content: space-between
  button
    margin: 0 1rem
    position: relative
    cursor: pointer
    font-size: 3.2rem
    border: none
    background: none
    font-family: $decoFont
    color: $color-mute-800
    transition: color $hoverTime
    white-space: nowrap
    span
      position: relative
      z-index: 1
    &:hover
      color: $color-mute-700
    &:after
      z-index: 0
      content: ' '
      position: absolute
      bottom: 0.4rem
      left: 50%
      transform: translateX(-50%)
      height: 6px
      width: 0
      background: $color-primary
      transition: width $hoverTime
      @media #{$mq_xs}
        bottom: 0
    &:hover
      &:after
        width: 90%
  @media #{$mq_xs}
    margin: 2rem 0
    padding: 0 0 1rem 0
    button
      font-size: 2.2rem

.search__filters-active
  button
    color: $color-mute-700
    &:after
      width: 90%

// Results

.search__result
  display: flex
  margin: 0 0 4rem 0
  h2
    font-size: 2.8rem
    line-height: 1.14
    color: $color-contrast-b
    display: inline
    background-position: 0% 90%
  @media #{$mq_xs}
    margin: 0 0 3rem 0
    h2
      font-size: 1.6rem
  a
    background-position: 0% 100%
  a, h2
    text-decoration: none
    background-image: linear-gradient($color-primary, $color-primary)
    background-size: 0% 4px
    background-repeat: no-repeat
    transition: background-size $hoverTime
    &:hover
      h2, &.search__result-category
        background-size: 100% 4px

.search__result-category
  text-transform: uppercase
  font-size: 1.6rem
  line-height: 1.25
  display: inline-block
  margin: 1rem 0 1.5rem 0
  font-weight: bold
  color: $color-mute-350
  @media #{$mq_xs}
    font-size: 1.2rem
    margin: 0.5rem 0

.search__result-copy-desc
  font-size: 1.6rem
  line-height: 1.25
  @media #{$mq_xs}
    display: none

.search__result-copy
  margin-left: 3rem

.search__result-photo
  position: relative
  overflow: hidden
  display: block
  .search__result-photo-img
    position: absolute
    transform: translate(-50%, -50%)
    left: 50%
    top: 50%
    transition: transform 0.5s
    height: 100%
  .search__result-photo-guide
    opacity: 0
  &:hover
    .search__result-photo-img
      transform: translate(-50%, -50%) scale(1.1)

.search__result-type-icon
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  z-index: 2

.search__item-icon
  position: absolute
  right: 0
  bottom: 0
  background: $color-primary
  padding: 0.5rem
  border-radius: 1rem 0 0 0
  width: 4rem
  height: 4rem
  svg
    width: 100%
    transform: translateY(1px)

// Pagination

.search__pagination-wrap
  border-top: 1px solid $color-mute-375
  text-align: center
  margin-bottom: 4rem
  padding-top: 4rem

.search__pagination
  display: inline-flex
  margin: 0 auto
  ul
    list-style: none
    padding: 0
    margin: 1.3rem 2rem
    display: block
    li
      float: left
      a
        padding: 0.5rem
        margin: 0.5rem
        font-size: 1.6rem
        color: $color-contrast-b

.search__pagination-transport
  background: $color-secondary
  display: block
  width: 5rem
  height: 5rem
  border-radius: 4px
  display: flex
  justify-content: center
  align-items: center
  transition: background $hoverTime
  svg
    transform: translateY(2px)
  &:hover
    background: $color-tertiary

.search__pagination-transport--next
  svg
    transform: translateY(2px) rotate(180deg)

.search__pagination-active
  span
    font-weight: bold
    border-bottom: 2px solid $color-contrast-b

// Ads

.search__ad
  margin-bottom: 4rem

// Notice

.search__notice
  font-size: 3.2rem
  color: $color-mute-850
  text-align: center
  padding: 2rem 0 6rem 0
