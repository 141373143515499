@import 'vars'

.video-preview
  position: absolute
  top: 0
  left: 0
  animation: videoEnter 0.5s forwards
  z-index: 1
  video
    width: 100%

@keyframes videoEnter
  0%
    opacity: 0
  100%
    opacity: 1
