@import 'vars'

.section-hero
  position: relative
  overflow: hidden
  z-index: 1

.section-hero__desc
  padding: $padding*2 $padding $padding $padding
  background: $color-primary
  text-align: center
  position: relative
  z-index: 1
  width: 100%
  color: $color-contrast-a
  margin-top: -5px
  h1
    font-weight: bold
    font-size: 9rem
    line-height: 10rem
    margin: 0.3rem 0
    text-transform: lowercase
    @media #{$mq_xs}
      font-size: 3.7rem
      line-height: 4.7rem
  h2
    text-transform: lowercase
    font-size: 4.6rem
    line-height: 5.6rem
    @media #{$mq_xs}
      font-size: 2.4rem
      line-height: 3.4rem
  @media #{$mq_xs}
    padding: $padding $padding $padding $padding

.section-hero__img
  position: relative
  z-index: 0